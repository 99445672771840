exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deine-geschichte-en-js": () => import("./../../../src/pages/deine-geschichte.en.js" /* webpackChunkName: "component---src-pages-deine-geschichte-en-js" */),
  "component---src-pages-deine-geschichte-js": () => import("./../../../src/pages/deine-geschichte.js" /* webpackChunkName: "component---src-pages-deine-geschichte-js" */),
  "component---src-pages-download-en-js": () => import("./../../../src/pages/download.en.js" /* webpackChunkName: "component---src-pages-download-en-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-en-mdx-slug-js": () => import("./../../../src/pages/en/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-en-mdx-slug-js" */),
  "component---src-pages-impressum-datenschutz-en-js": () => import("./../../../src/pages/impressum-datenschutz.en.js" /* webpackChunkName: "component---src-pages-impressum-datenschutz-en-js" */),
  "component---src-pages-impressum-datenschutz-js": () => import("./../../../src/pages/impressum-datenschutz.js" /* webpackChunkName: "component---src-pages-impressum-datenschutz-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-splash-en-js": () => import("./../../../src/pages/index-splash.en.js" /* webpackChunkName: "component---src-pages-index-splash-en-js" */),
  "component---src-pages-index-splash-js": () => import("./../../../src/pages/index-splash.js" /* webpackChunkName: "component---src-pages-index-splash-js" */),
  "component---src-pages-karte-en-js": () => import("./../../../src/pages/karte.en.js" /* webpackChunkName: "component---src-pages-karte-en-js" */),
  "component---src-pages-karte-js": () => import("./../../../src/pages/karte.js" /* webpackChunkName: "component---src-pages-karte-js" */),
  "component---src-pages-mdx-slug-js": () => import("./../../../src/pages/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-mdx-slug-js" */),
  "component---src-pages-suche-en-js": () => import("./../../../src/pages/suche.en.js" /* webpackChunkName: "component---src-pages-suche-en-js" */),
  "component---src-pages-suche-js": () => import("./../../../src/pages/suche.js" /* webpackChunkName: "component---src-pages-suche-js" */),
  "component---src-pages-uber-uns-en-js": () => import("./../../../src/pages/uber-uns.en.js" /* webpackChunkName: "component---src-pages-uber-uns-en-js" */),
  "component---src-pages-uber-uns-js": () => import("./../../../src/pages/uber-uns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

